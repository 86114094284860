<template>
    <div class="myPlayer_container" >
        <!-- :src="url" -->
      <!-- <video 
        controls="controls"
        id="videoplayer"
        x5-video-player-type="h5-page"
        preload="metadata"
        playsinline="true"
        webkit-playsinline="true"
        x-webkit-airplay="true"
        x5-video-orientation="portraint"
        ref='player'
        @loadedmetadata='loaded'
      >
        <source  :src="url" type="application/x-mpegURL" />
      </video> -->
      <div class='myPlayer_video_main' ref='videoMain'>
        
        <video  
          v-if="showVideo"
          ref="player" 
          class='myPlayer_video  video-js vjs-big-play-centered vjs-fluid'
          x5-video-player-type="h5-page"
          preload="metadata"
          playsinline="true"
          webkit-playsinline="true"
          x-webkit-airplay="true"
          x5-video-orientation="portraint"
          x5-video-player-fullscreen=""
          @fullscreenchange='fullscreenchange'
          @ended="playEnd"
        >
          <source  :src="url" :type="type" />
        </video>
      </div>
      <div class='myPlayer_video_controls_cover' ref='controlsMain'  :class='{myPlayer_video_controls_fulllscrenn:isFullscreen}' @click.stop='toggleControl' v-if='false'>
        <div class="myPlayer_video_controls_playbutton" v-show='showControl'>
          <i :class='`el-icon-video-${getStatus() ? "play" : "pause"}`' v-show='getStatus()'  @click.stop="play"></i>
        </div>
        <div class='myPlayer_video_controls' v-show='showControl'>
          <div class='myPlayer_video_controls_main'>
            <div class="myPlayer_video_pause myPlayer_video_controls_button" @click.stop='toggle'>
              <i :class='`el-icon-video-${getStatus() ? "play" : "pause"}`'></i>
            </div>
            <div class="myPlayer_video_fullqueen myPlayer_video_controls_button"  @click.stop='fullScreen'>
              <i class='el-icon-full-screen'></i>
            </div>
          </div>
        </div>
      </div>
      <div v-show='false'>{{index}}</div>
      <Cover ref="cover" :url="cover" :isShowButton="isShowButton" v-if="showCover" @click="play()"></Cover>
    </div>
</template>
<script>
import Cover from '@/components/unit/Cover'
export default {
  name:'myPlayer',
  props:{
    isShowCover:{
      type:Boolean,
      default:false
    },
    isShowButton:{
      type:Boolean,
      default:false
    },
  },
  data:()=>{
    return {
      cover:"",
      showCover:false,
      url:'',
      index:0,
      type:"application/x-mpegURL",
      player:null,
      showControl:false,
      isFullscreen:false,
      timer:null,
      showVideo:true,
      options:{
        controls: true,//用户可以与之交互的控件
        // loop:true,//视频一结束就重新开始
        predload:'metadata',
        muted:false,//默认情况下将使所有音频静音
        // aspectRatio:"16:9",//显示比率
        sources: [
            {
              src:'',
              type: 'application/x-mpegURL'
            }
        ]
      }
    }
  },
  components:{
    Cover
  },
  mounted(){
    this.showCover = !!this.$props.isShowCover
  },
  beforeDestroy() {
      if (this.player) {
          this.player.dispose()
      }
  },
  methods:{
    getStatus(){
      return this.player ? this.player.paused() : false;
    },
    play(status){
      if(status){
        this.player.play();
        this.showCover = false
        this.showControl = false;
        this.$emit("beginVideo")
        return
      }
      setTimeout(()=>{
        this.player.play();
        this.showCover = false
        this.showControl = false;
        this.$emit("beginVideo")
      },2000)
    },
    pause(){
      this.player.pause();
    },
    toggleControl(){
      this.showControl = !this.showControl;
    },
    setControl(status){
      this.showControl = status;
    },
    toggle(){
      let key = this.getStatus() ? 'play' : 'pause';
      this[key]();
      this.index++;
    },
    fullScreen(){
      this.isFullscreen = this.player.isFullscreen();
      if(!this.isFullscreen){
        this.player.requestFullscreen()
      }else{
        this.player.exitFullscreen();
      }
    },
    setCover(cover){
      this.cover = cover
      this.showCover = true
      this.$nextTick(()=>{
        this.$refs.cover.setURL(cover)
      })
    },
    switch(url,type="application/x-mpegURL"){
      if(!url) return;
      // this.player && this.player.dispose()
      this.showVideo = true;
      this.$nextTick(()=>{
        // console.log(this.url)
        this.url = url;
        this.type = type;
        this.options.sources[0].src = url;
        this.options.sources[0].type = type;
        let that = this;
        if(this.player){
          this.player.src(url);  //重置video的src
          this.player.load(url);  //使video重新加载
          this.player.play()
          return;
        }
        console.log(url,type)
        this.player = this.$video(this.$refs.player,this.options,function onPlayerReady(){
          this.pause()
          this.on('fullscreenchange', that.fullscreenchange)
          this.on('ended', that.playEnd)
          that.loaded();
        })
      })
    },
    playEnd(){
      // this.showVideo = false
      this.$emit("endVideo")
    },
    fullscreenchange(e){
      this.isFullscreen = this.player.isFullscreen();
      this.showControl = this.isFullscreen;
      // this.$refs.videoMain.children[0].appendChild(this.$refs.controlsMain)
      if(!this.$tools.isAndroid()){
        setTimeout(()=>{
          this.play()
        },1000)
      }
    },
    setMuted(status){
      this.player.setMuted(status)
    },
    loaded(){
      this.$emit('loaded');
    },
  }
}
</script>
<style>
/* 时间进度条 */
.myPlayer_container,.myPlayer_video{
  width:100%;
  height:100%;
  overflow: hidden;
  position: relative;
}
.myPlayer_container video::-webkit-media-controls-timeline {
    display: none !important;
}
/* 全屏按钮 */
.myPlayer_container video::-webkit-media-controls-fullscreen-button {
    display:block !important;
    content: "\e719";
}
/* 音量按钮 */
.myPlayer_container video::-webkit-media-controls-mute-button {
    display: none!important;            
}
.myPlayer_video_main{
  position: relative;
  z-index:1;
  height:100%;
  width:100%;
}
.myPlayer_video_main video{
  height:100% !important;
  width:100% !important;
  position: absolute !important;
  left:0;
  top:0 !important;
}
/* .myPlayer_video{
  backface-visibility:hidden;
  -webkit-backface-visibility:hidden;
  -moz-backface-visibility:hidden;  
  -ms-backface-visibility:hidden;  
  -webkit-perspective: 0;
  -webkit-transform: translate3d(0,0,0);
  visibility:visible;
} */
.myPlayer_video_controls_cover{
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:transparent;
  z-index:1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.myPlayer_video_controls_playbutton{
    width: 100%;
    flex:1 0 0;
    font-size: 24vw;
    color: white;
    text-shadow:-3px 0 black,0 3px black,3px 0 black,0 -3px black;
    display: flex;
    align-items: center;
    justify-content:center;
}
.myPlayer_video_controls{
  flex:8vw 0 0 ;
  width:100%;
  z-index:10;
}
.myPlayer_video_controls_main{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width:100%;
  height:100%;
  background-image:linear-gradient(0deg, #000, #fff);
}
.myPlayer_video_controls_button{
  font-size:4vw;
  color:#fff;
  width:8vw;
  height:100%;
  display: flex;
  align-items: center;
  justify-content:center;

}

.myPlayer_video_controls_fulllscrenn{
  /* object-fit: contain;
  position: fixed !important;
  z-index:1000000000000000000;
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  box-sizing: border-box !important;
  min-width: 0px !important;
  max-width: none !important;
  min-height: 0px !important;
  max-height: none !important;
  width: 100% !important;
  height: 100% !important;
  transform: none !important;
  margin: 0px !important; */
}
.vjs-control-bar{
  opacity: 1;
  position: absolute;
  /* bottom:12px  !important; */
  background-image:linear-gradient(0deg, #000, #fff);
}
.vjs-volume-panel,.vjs-live-control,.vjs-picture-in-picture-control{
  display:none !important;
}
.vjs-fullscreen-control{
  position: absolute  !important;
  right:0;
}
.vjs-button>.vjs-icon-placeholder:before{
  display: inline-block;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  font-size:4vw;
}
</style>