<template>
  <div class="courseware-preview-container">
    <div class="courseware-preview-main">
      <div class="courseware-preview-player">
        <Player ref="player" @beginVideo="beginVideo" :isShowButton="false" :isShowCover="true"></Player>
      </div>
      <div class="courseware-preview-progress">
        <el-slider size="large" :show-tooltip="false" :max="getCourseware()?.play_video_time || 100" v-model="videoTime" />
      </div>
      <div class="courseware-preview-time">
        <span>{{ currVideoTime }}</span>
        <span>{{ maxCoursewareTime }}</span>
      </div>
      <div class="courseware-preview-control" v-if="isSelect()">
        <el-icon :size="iconSize" @click="prepVideo" ><CaretLeft  v-if="!isFisrt()"/></el-icon>
        <el-icon :size="iconSize" @click="pauseVideo" v-if="isPlaying"><VideoPause  /></el-icon>
        <el-icon :size="iconSize" @click="playVideo" v-else><VideoPlay  /></el-icon>
        <el-icon :size="iconSize" @click="nextVideo" ><CaretRight v-if="!isLast()" /></el-icon>
      </div>
    </div>
    <div class="courseware-preview-button global_container_center" v-if="isSelect()">
      <CustomButton size="middle" :class="{'courseware-preview-button-select':isUse()}"  @click="doSelect" round>选择</CustomButton>
    </div>
  </div>
</template>
<script>
import Player from '@/components/player/player'
import { VideoPause,CaretLeft,CaretRight,VideoPlay } from "@element-plus/icons"
import CustomButton  from '@/components/unit/CustomButton'

export default {
  name:"courseware-preview",
  components:{
    Player,
    VideoPlay,
    CustomButton,
    VideoPause,
    CaretLeft,
    CaretRight,

  },
  data(){
    return {
      config:{
        coursewares:[],
        allUse:[]
      },
      iconSize:36,
      videoTime:0,
      currCoursewareId:0,
      isPlaying:false,
      isLoading:false,
      timer:null
    }
  },
  computed:{
    currVideoTime(){
      return this.$tools.getShowTime(this.videoTime*1000,"mm:ss")
    },
    maxCoursewareTime(){
      if(this.config.coursewares.length == 0) return ""
      return this.$tools.getShowTime(this.getCourseware().play_video_time*1000,"mm:ss")
    }
  },
  created(){
    this.currCoursewareId = Number(this.$route.query.coursewareId)
    this.loadCourseware()
    if(this.isSelect()){
      this.loadClassUse()
    }
  },
  methods:{
    calcVideoTime(){
      if(this.timer){
        clearTimeout(this.timer)
        this.timer = null
      }
      if(!this.isPlaying) return;
      this.videoTime += 1
      setTimeout(()=>{
        this.calcVideoTime()
      },1000)
    },
    isSelect(){
      return !!Number(this.$route.query.select)
    },
    isUse(){
      return this.config.allUse.includes(this.currCoursewareId)
    },
    isFisrt(){
      if(this.config.coursewares.length == 0) return false
      let courseware = this.config.coursewares.slice(0,1)[0]
      return  courseware.id == this.currCoursewareId
    },
    isLast(){
      if(this.config.coursewares.length == 0) return false
      let courseware = this.config.coursewares.slice(-1)[0]
      console.log(courseware)
      return  courseware.id == this.currCoursewareId
    },
    beginVideo(){
      this.isPlaying = true
      this.calcVideoTime()
    },
    endVideo(){
      this.isPlaying = false
      this.videoTime = this.getCourseware().play_video_time
    },

    prepVideo(){
      let index = this.config.coursewares.findIndex(courseware=>courseware.id==this.currCoursewareId)
      if(index <= 0) return;
      let courseware = this.config.coursewares[index-1]
      this.currCoursewareId = courseware.id
      this.switch()
    },
    nextVideo(){
      let index = this.config.coursewares.findIndex(courseware=>courseware.id==this.currCoursewareId)
      if(index >= this.config.coursewares.length-1) return;
      let courseware = this.config.coursewares[index+1]
      this.currCoursewareId = courseware.id
      this.switch()
    },
    playVideo(){
      this.$refs.player.play(true)
    },
    pauseVideo(){
      this.$refs.player.pause()
      this.isPlaying = false
    },
    getCourseware(){
      return this.config.coursewares.find(item=>item.id == this.currCoursewareId)
    },
    loadCourseware(){
      if(!this.$route.query.select) return;
      this.$axios.get(this.$)
    },
    loadCourseware(){
      this.$axios.get(this.$urls.admin.courseware).then(res=>{
        if(!this.isSelect()) this.config.coursewares = res.data.filter(item=>item.id == this.$route.query.coursewareId)
        else {
          this.config.coursewares = res.data
        }
        this.switch()
      })
    },
    loadClassUse(){
      this.$axios.get(this.$urls.admin.haveCourseware).then(res=>{
        this.config.allUse = res.data.map(x=>x.id)
      })
    },
    switch(){
      this.$nextTick(()=>{
        let courseware = this.config.coursewares.find(item=>item.id == this.currCoursewareId)
        this.$refs.player.switch(courseware.play_video_url,'video/mp4')
        this.$refs.player.setCover(courseware.cover)
        this.isPlaying = false
        this.videoTime = 0;
      })
    },
    doSelect(){
      let data = this.config.coursewares.find(item=>item.id==this.currCoursewareId)
      if(this.config.allUse.includes(data.id) || this.isLoading) return
      this.config.allUse.push(data.id)
      if(this.$route.query.type == 0){
        let meetingInfo = this.$tools.getLocalCookie("meetingInfo")
        meetingInfo.courseware_id = data.id
        this.$tools.setLocalCookie("meetingInfo",meetingInfo)
        this.$router.push({
          path:'/meetinfo',
          query:this.$route.query
        })
      }else{
        this.doCompleteCourseware(data.id)
      }
      },
      doCompleteCourseware(id){
        this.isLoading = true
        this.$axios.post(this.$urls.admin.bindCourseware,{id:this.$route.query.meetId,courware_id:id}).then(res=>{
          setTimeout(()=>{this.isLoading = false},1000)
          this.$router.push({
            path:'/meetlist'
          })
        }).catch(res=>{
          setTimeout(()=>{this.isLoading = false},1000)
        })
      },
  }
}
</script>
<style>
.courseware-preview-container{
  display: flex;
  width:100%;
  height:100%;
  flex-direction: column;
  padding:4vw;
  background:#f2f2f2
}
.courseware-preview-player{
  height:92vw;
}
.courseware-preview-main{
  display: flex;
  flex-direction: column;
  width:92vw;
  background:#fff
}
.courseware-preview-progress{
  padding:2vw 8vw 0;
}
.courseware-preview-time{
  padding:0 4vw 4vw;
  display: flex;
  justify-content: space-between;
}
.courseware-preview-control{
  padding:2vw 20vw;
  display: flex;
  justify-content: space-between;
}
.courseware-preview-button{
  margin-top:8vw;
}
.courseware-preview-button .customButton_container{
  width:30vw;
}
.courseware-preview-button-select{
  background:#bfbfbf;
}
</style>