<template>
    <div class="customButton_container global_container_center customButton_background global_primary_background_color" :class="getClass()" :style="getStyle()" >
        <slot></slot>
    </div>
</template>
<script>
export default {
    name:"customButton",
    props:{
        size:{ // size用于区分 长 中 短
            type:String,
            default:"middle"
        },
        round:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
            config:{
                height:{
                    large:12,
                    middle:10,
                    small:8
                }
            }
        }
    },
    methods:{
        getStyle(){
            let height = this.config.height[this.size];
            let style = `height:${height}vw;`
            if(this.round) style = `${style}border-radius:${height/2}vw;`
            return style
        },
        getClass(){
            return `customButton_${this.size}`
        }
    }
}
</script>
<style >
.customButton_container{
    font-size:4vw;
    /* color:#fff; */
    margin:1vw;
    padding: 0 2vw;
    border-radius:.5vw;
    color:#fff;
}
.customButton_large{
    width:100%
}
.customButton_middle{
    min-width:21.33vw;
}
.customButton_small{
}
.customButton_background{
    background-size:100% 100%;
    background-repeat: no-repeat;
}
</style>